.sc-carousel {
    &-inner {
        .slick-slide {
            margin: 0 0.75rem;
        }

        .slick-list {
            margin: 0 -0.75rem;
        }
    }

    &-item {
        text-align: center;

        img {
            display: block;
            margin: 0 auto 1rem;
        }

        .content-block {
            transition: border-color $mainTransition;

            &:hover {
                box-shadow: none;
                border-color: #eaeaea;
            }
        }
    }
}