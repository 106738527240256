.sc-banner-plus {
    @include media-breakpoint-up(md) {
        .content-block {
            padding: 1.5rem;
        }
    }

    &-25 {
        flex: 0 0 100%;
        width: 100%;

        @include media-breakpoint-up(sm) {
            .content-block {
                display: flex;
                flex-direction: column;
            }

            .sc-banner-plus-item-box {
                flex: 1;

                p {
                    margin-bottom: 1.5rem;
                }
            }

            .sc-banner-plus-item-img {
                flex: 0 0 40%;
                width: 40%;
                padding-left: 1rem;
            }
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            width: 50%;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 25%;
            width: 25%;

            .sc-banner-plus-title {
                font-size: 1.25rem;
            }

            .sc-banner-plus-text {
                font-size: 0.875rem;
            }
        }

    }

    &-50 {
        flex: 0 0 100%;
        width: 100%;

        @include media-breakpoint-between(sm, xl) {
            .content-block {
                display: flex;
                flex-direction: column;
            }

            .sc-banner-plus-item-box {
                flex: 1;

                p {
                    margin-bottom: 1.5rem;
                }
            }

            .sc-banner-plus-item-img {
                flex: 0 0 40%;
                width: 40%;
                padding-left: 1rem;
            }
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            width: 50%;

            .sc-banner-plus-item-img {
                height: 200px;
            }
        }
    }

    &-100 {
        flex: 0 0 100%;
        width: 100%;

        @include media-breakpoint-only(sm) {
            .content-block {
                display: flex;
                flex-direction: column;
            }

            .sc-banner-plus-item-box {
                flex: 1;

                p {
                    margin-bottom: 1.5rem;
                }
            }

            .sc-banner-plus-item-img {
                flex: 0 0 40%;
                width: 40%;
                padding-left: 1rem;
            }
        }
    }

    &-item {
        position: relative;

        @include media-breakpoint-down(sm) {
            width: 100%;

            .content-block {
                text-align: center;
            }

            &-box {
                display: flex;
                flex-direction: column;
                align-items: center !important;
            }
        }
    }

    &-title {
        margin-bottom: 1rem;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0.5rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.25rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.5rem;
        }
    }

    &-text {
        padding-top: 1rem;

        p {
            color: $lightTextColor;
        }

        @include media-breakpoint-down(sm) {
            align-items: center !important;
            order: 1;

            p {
                margin-bottom: 1rem;
            }
        }

        @include media-breakpoint-up(md) {
            padding-top: 1.5rem;
        }
    }

    .sc-banner-plus-item-img {
        @include media-breakpoint-down(sm) {
            order: 0;
        }

        img {
            max-width: 100%;
            block-size: auto;
        }
    }
}