.sc-category-wall {
    &-item {
        flex: 0 0 auto;
        width: 50%;

        @include media-breakpoint-up(md) {
            width: 33.33333333%;
        }

        @include media-breakpoint-up(lg) {
            width: 25%;
        }

        @include media-breakpoint-up(xl) {
            width: 16.66666667%;
        }

        .content-block {
            padding-right: 1.25rem;
            padding-left: 1.25rem;
            position: relative;

            &:hover {
                box-shadow: none;
            }

            > a {
                margin-top: 1rem;
            }
        }

        &-list {
            position: absolute;
            z-index: 2;
            top: -1px;
            right: -1px;
            left: -1px;
            padding: 1rem;
            background: #FFFFFF;
            border: 1px solid #F2F4F8;
            transition: box-shadow $mainTransition;
            border-radius: 0.25rem;
            min-height: calc(100% + 2px);
            
            &.d-none {
                visibility: hidden;

                a {
                    pointer-events: none;
                }
            }

            &.d-flex {
                visibility: visible;

                a {
                    pointer-events: all;
                }
            }

            .blue-link {
                display: block;

                &:not(:last-child) {
                    margin-bottom: 0.25rem;
                }
            }

            &.animated {
                box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
                0px 8px 16px rgba(96, 97, 112, 0.16);
            }

            .sc-btn {
                flex: 0 0 28px;
                max-height: 28px;
                line-height: 28px;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}