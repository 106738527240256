.sc-manufacturer {
    &-links {
        top: 55px;
    }
    
    &-title {
        font-size: 2.25rem;
        border-bottom: $mainBorder;
    }

    &-item {
        a:hover {
            .sc-manufacturer-item-title {
                text-decoration: underline;

                &:hover {
                    color: $linksColor;
                    text-decoration: none;
                }
            }
        }

        & + .sc-manufacturer-title {
            border-top: $mainBorder;
        }
    }
}