.sc-store-reviews {
    &-top {
        border-bottom: $mainBorder;
    }

    .sc-product-content-reviews-rating-value {
        flex-grow: 1;
        min-width: 34px;
    }

    .sc-product-content-reviews-rating-stars {
        flex-direction: row;
    }

    .sc-dropdown-toggle::after {
        display: none;
    }

    &-rating {
        @include media-breakpoint-down(md) {
            padding: 1rem 0;
            border-top: $mainBorder;
        }
    }

    &-form {
        background: #F2F4F8;
        top: calc(51px + 1.5rem);
        padding: 1.5rem;
        

        @include media-breakpoint-down(xl) {
            max-height: 0;
            transition: max-height $mainTransition, opacity $mainTransition 0.2s;
            overflow: hidden;
            padding: 0;
            opacity: 0;

            &.active {
                max-height: 1000px;
                padding: 1.5rem;
                margin-bottom: 1.5rem;
                opacity: 1;
            }

            &-toggle,
            .sc-btn-primary {
                max-width: 378px;
            }
        }

        &-item {
            label {
                @media (max-width: 330px) {
                    font-size: 0.75rem;
                }

                @media (max-width: $break-xxs) {
                    font-size: 0.875rem;
                }
            }
        }

        .sc-module-rating-star {
            width: 18px;
            height: 18px;
            cursor: pointer;

            @media (min-width: 400px) {
                width: 24px;
                height: 24px;
            }

            & + .sc-module-rating-star {
                margin-left: 0.5rem;
            }
        }

        a {
            color: $linksColor;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .sc-product-content-reviews-item-top {
        @media (max-width: $break-xxs) {
            flex-direction: column;

            > *:last-child {
                margin-top: 1rem;
                align-items: flex-start !important;
            }
        }
    }
}