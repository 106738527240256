.sc-footer {
    background: #2A2568;
    color: #FFFFFF;

    hr {
        margin: 0;
        background-color: #FFFFFF;
        padding: 0;
        opacity: 0.08;
    }

    &-top {
        padding: 2rem 0;

        @include media-breakpoint-up(xl) {
            padding: 2.5rem 0;
        }
    }

    &-title {
        color: #C7C9D9;

        @include media-breakpoint-down(sm) {
            font-size: 0.875rem;
        }
    }

    &-phones,
    &-socials {
        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 16.66666667%;
            max-width: 16.66666667%;
        }
    }

    &-phones {
        order: 0;

        a {
            text-decoration: underline;

            &+a {
                margin-top: 0.25rem;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-socials {
        order: 1;

        @include media-breakpoint-down(md) {
            margin-top: 2rem;
        }

        @include media-breakpoint-up(xl) {
            order: 2;
            margin-left: auto;
        }

        &-item {
            flex: 0 0 40px;
            max-width: 40px;
            height: 40px;
            font-size: 0;
            background-color: #FFFFFF;
            border-radius: 25px;
            transition: background-color $mainTransition;

            &+& {
                margin-left: 0.5rem;
            }

            span {
                width: 14px;
                height: 14px;
                mask-size: cover;
                mask-repeat: no-repeat;
                display: block;
                transition: background $mainTransition;
                background: #28293D;
            }

            i {
                color: #28293D;
                font-size: 1rem;
                transition: color $mainTransition;
            }

            &-facebook {
                mask: url('../img/sprite.svg#include--facebook-icon');
            }

            &-twitter {
                mask: url('../img/sprite.svg#include--twitter-icon');
            }

            &-tiktok {
                mask: url('../img/sprite.svg#include--tiktok-icon');
            }

            &-instagram {
                mask: url('../img/sprite.svg#include--instagram-icon');
                width: 18px !important;
                height: 18px !important;
            }

            &:hover {
                background-color: $primaryColor;

                span {
                    background: #FFFFFF;
                }

                i {
                    color: #FFFFFF;
                }
            }
        }
    }

    &-subscribe {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;

        @include media-breakpoint-down(md) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        @include media-breakpoint-down(xl) {
            margin-top: 2rem;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 66.666666%;
            max-width: 66.666666%;
            order: 1;
        }

        &-form {
            width: 420px;
            max-width: 100%;

            .sc-btn {
                border-radius: 0 0.25rem 0.25rem 0;
                width: auto !important;
            }
        }

        .form-control {
            border-right: 0;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .form-check-input {
            float: none;
            border-radius: 0.25rem !important;
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
            border: 0;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .form-check-label {
            color: #f4f4f4;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        button {
            font-size: 12px;
            color: #f4f4f4;
            height: 44px;
            line-height: 44px;
        }
    }

    &-middle {
        @include media-breakpoint-down(md) {
            >div {
                margin: 0;
                padding: 0;
            }

            .sc-footer-title {
                padding: 1rem 1.5rem;
                background: rgba(255, 255, 255, 0.03);
                border-bottom: 1px solid rgba(255, 255, 255, 0.06);
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                &:after {
                    content: '';
                    background-image: url(../img/sprite.svg#include--footer-mobile-chevron);
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 18px;
                    height: 14px;
                    transition: transform $mainTransition;
                }

                &.active {
                    &:after {
                        transform: rotate(180deg);
                    }

                    &+.sc-footer-middle-info {
                        max-height: 600px;
                        padding: 1rem 1.5rem;
                    }
                }
            }

            &-info {
                max-height: 0;
                padding: 0 1.5rem;
                overflow: hidden;
                transition: max-height 0.4s ease, padding $mainTransition;
            }
        }

        @include media-breakpoint-up(xl) {
            hr {
                &:first-child {
                    margin-bottom: 2rem !important;
                }

                &:last-child {
                    margin-top: 2rem !important;
                }
            }
        }
    }

    &-list {
        a {
            text-decoration: underline;
            line-height: 1.5rem;

            @include media-breakpoint-down(md) {
                font-size: 0.875rem !important;
            }

            @include media-breakpoint-up(md) {
                line-height: 1.375rem;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-email {
        text-decoration: underline;
        letter-spacing: 0.3px;

        &:hover {
            text-decoration: none;
        }
    }

    &-shedule {
        line-height: 1rem;
    }

    &-payments {
        &-list {
            flex-wrap: wrap;
        }

        &-item {
            background-repeat: no-repeat;
            display: inline-block;
            background-image: url("../img/sprite-sc.png");
            flex: 0 0 53px;
            max-width: 53px;
            height: 33px;
            margin-bottom: 6px;
            border-radius: 6px;
            border: 1px solid #E9E9E9;

            &:not(:last-child) {
                margin-right: 6px;
            }

            @media (min-width: $break-sm) {
                margin-bottom: 10px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            &.privat24 {
                background-position: -199px -138px;
            }

            &.mastercard {
                background-position: -67px -2px;
            }

            &.maestro {
                background-position: -133px 0;
            }

            &.visa {
                background-position: 0 -1px;
            }

            &.skrill {
                background-position: -199px -92px;
            }

            &.liqpay {
                background-position: -66px -46px;
            }

            &.paypal {
                background-position: 0 -47px;
            }

            &.custom-payment {
                background-position: -133px -46px;

                img {
                    vertical-align: baseline;
                }
            }
        }
    }

    &-credits {
        padding: 2rem 0;
    }
}

#back-top {
    position: fixed;
    right: 1.5rem;
    bottom: 136px;
    width: 2rem;
    height: 2rem;
    z-index: 1020;
    background: rgba(#FFFFFF, 0.8);
    margin-bottom: 0;
    transition: background $mainTransition;
    display: none;
    cursor: pointer;
    border: 1px solid #fbfbfb;
    align-items: center;
    justify-content: center;

    @media (min-width: 420px) {
        right: 3rem;
        bottom: 156px;
    }

    @media (min-width: $break-md) {
        right: 2.75rem;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        bottom: 120px;
    }

    @media (min-width: $break-lg) {
        right: 2.5rem;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
    }

    &:hover {
        background: rgba(#FFFFFF, 1);
    }

    &:focus {
        box-shadow: none;
    }

    &.active {
        display: flex;
    }

    i {
        color: $darkTextColor;
    }
}