.sc-last-news {
    &-item {
        @include media-breakpoint-down(md) {
            padding: 0;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        @include media-breakpoint-up(xl) {
            width: 25%;
        }

        img {
            block-size: auto;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            @media (min-width: 400px) {
                .content-block {
                    flex-direction: row !important;
                    padding-bottom: 0 !important;

                    > a {
                        width: 148px;
                        flex: 0 0 148px;
                    }
                }

                .sc-last-news-title {
                    order: 0;
                    padding-top: 1rem;
                }

                .sc-last-news-info {
                    order: 1;
                }

                img {
                    height: 100%;
                    width: 148px;
                    object-fit: cover;
                }
            }
        }
    }

    .sc-module-sticker {
        align-self: center;
    }

    &-title:hover {
        text-decoration: underline;
        color: $linksColor;
    }
}