//colors
$primaryColor: #4F43E3;
$primaryDarkColor: #282182;
$primaryLightColor: #F2F4F8;
$linksColor: #0063F7;
$darkTextColor: #1C1C28;
$secondaryTextColor: #555770;
$lightTextColor: #8F90A6;
$greenColor: #06C270;
$redColor: #E53535;

$mainTransition: 0.3s ease-out;
$mainBorder: 1px solid #F2F4F8;

// min-width breakpoints
$break-xxs: 360px; // @media (min-width: $break-xxs) {}
$break-xs: 420px; // @media (min-width: $break-xs) {}
$break-sm: 576px; // @media (min-width: $break-sm) {}
$break-md: 768px; // @media (min-width: $break-md) {}
$break-lg: 992px; // @media (min-width: $break-lg) {}
$break-xl: 1200px; // @media (min-width: $break-xl) {}
$break-xxl: 1600px; // @media (min-width: $break-xxl) {}

// max-width breakpoints
$break-xxs-max: 419px; // @media (max-width: $break-xxs-max) {}
$break-xs-max: 575px; // @media (max-width: $break-xs-max) {}
$break-sm-max: 767px; // @media (max-width: $break-sm-max) {}
$break-md-max: 991px; // @media (max-width: $break-md-max) {}
$break-lg-max: 1199px; // @media (max-width: $break-lg-max) {}
$break-xl-max: 1599px; // @media (max-width: $break-xl-max) {}
