#column-left:not(.display-column),
#column-right:not(.display-column) {
    @include media-breakpoint-down(xl) {
        display: none;
    }
}

#column-left,
#column-right {
    > * + * {
        margin-top: 0.5rem;
    }
}

.sc-column {
    .content-block:not(.with-border) {
        border-bottom: 0;
    }

    &-title {
        padding: 1.25rem 1rem;
        border-bottom: 1px solid #F2F4F8;
    }

    &-categories {
        color: #555770;

        &-item {

            > *:not(ul) {
                padding: 1rem;
                display: block;
                border-bottom: 1px solid #F2F4F8;
                cursor: pointer;
                transition: background-color $mainTransition;

                &:hover {
                    background-color: #FCFCFC;
                }
            }

            &.clicked {
                > span {
                    font-weight: 600;

                    > .sc-column-categories-toggle {
                        filter: opacity(0.7);
                        transform: rotate(180deg);
                        background: #a2a8c2 !important;

                        .sc-btn-icon {
                            background: #FFFFFF;
                        }
                    }
                }
            }

            a:hover {
                text-decoration: underline;
                color: $linksColor;
            }
        }

        &-toggle {
            width: 20px;
            height: 20px;
            filter: none;
            background: #FFFFFF !important;
            transition: transform $mainTransition;

            &:hover {
                background: #a2a8c2 !important;
            }
        }

        &-children {
            max-height: 0;
            overflow-y: hidden;
            transition: max-height 0.4s ease-in-out 0.05s;

            &.sc-categories-2 {
                background: #F4F7FA;
                font-weight: 400;

                a {
                    color: $secondaryTextColor;
                }

                li.active a {
                    color: $linksColor;
                }
            }

            &.active {
                max-height: 3000px;
            }
        }

        .sc-categories-3 {
            span {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;

                &:before {
                    content: '●';
                    display: inline-block;
                    margin: 0 0.5rem;
                    font-size: 6px;
                }
            }

            li.active a {
                color: $linksColor;
            }
        } 
    }

    .sc-module-item {
        width: 100%;
    }

    .slick-slider-btn {
        opacity: 0.8;
        z-index: 10;

        &:hover {
            opacity: 1;
        }
    }
    
    &-products {
        display: flex;
        flex-wrap: nowrap;

        .sc-column-item {
            flex: 0 0 100%;
            display: none;
            min-height: 440px;
        }

        .slick-slider-btn {
            top: 50%;
        }

        .sc-module-img .img-fluid {
            max-height: 180px;
        }
    }

    &-blog-articles {
        .content-block {
            border-radius: 0;
        }

        .oct-lazy {
            max-height: 280px;;
        }
    }

    .slick-slider-btn-next {
        right: 1rem;
    }

    .slick-slider-btn-prev {
        left: 1rem;
    }

    .sc-banner-plus-title {
        font-size: 1rem;

        & + p {
            color: $lightTextColor;
        }
    }

    .sc-column-banners-box {
        opacity: 0;
        visibility: hidden;
        transition: opacity 1s ease;

        &.slick-initialized {
            visibility: visible;
            opacity: 1;
        }
    }

    .sc-banner-plus-item {
        height: auto !important;
    }
}

@keyframes fadeInColumn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeInColumn {
    animation-name: fadeInColumn;
    animation-duration: 0.8s;
}