.form-label {
    font-size: 0.875rem;
}

.form-control {
    color: $darkTextColor;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: #FFFFFF;
    border: 1px solid #E4E4EB;
    padding: 0.625rem 1rem;
    border-radius: 0.25rem;

    @include media-breakpoint-down(lg) {
        transition: unset;
    }

    &::placeholder {
        color: $lightTextColor;
        font-size: 0.875rem;
    }

    &:focus {
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.02);
        border: 1px solid #D3D8E7;
    }
}

.form-select {
    cursor: pointer;
    background-image: url(../img/sprite.svg#include--input-sort);
    background-size: 10px;

    

    &:disabled {
        background-color: #F8FAFB;
        color: $lightTextColor;
    }
}

.form-check {

    &-input {
        width: 1rem;
        height: 1rem;
        border-radius: 0.125rem !important;
        margin-right: 0.5rem;
        margin-top: 0;
        border-color: $lightTextColor;
        flex: 0 0 1rem;

        &:checked {
            background-color: $primaryColor;
            border-color: $primaryColor;
        }

        &[type=radio] {
            border-radius: 50% !important;
        }
    }

    &-label {
        font-size: 0.75rem;
        color: $darkTextColor;
    }
}

.input-group .sc-btn {
    width: auto;
    height: 48px;
    width: 48px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    position: relative;
    left: 0;
    box-shadow: none;
    filter: none;
}

// Default Radio

.input-radio {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &:checked+.radio-box::after {
        opacity: 1;
    }

    &:focus+.radio-box {
        box-shadow: inset 0 0 0 0.1rem rgba(198, 198, 198, 0.1);
    }
}

.radio label {
    margin: 0 20px 0 26px;
    position: relative;
    cursor: pointer;
}

.radio-box {
    position: absolute;
    top: 4px;
    width: 16px;
    height: 16px;
    background-color: #FFF;
    border: 1px solid #d1d2d5;
    border-radius: 50%;
    margin-left: -26px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &::after {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 6px;
        height: 6px;
        background-color: $primaryColor;
        border-radius: 50%;
        opacity: 0;
        transition: opacity $mainTransition;
    }
}

.sc-select {
    height: 44px;
    font-size: 12px;
    background: #FDFDFD url("../img/sprite.svg#include--input-sort") no-repeat right 8px center;
    background-size: 10px;
    background-color: #FDFDFD;
    border: $mainBorder;
    border-radius: 4px;
    padding: 0 1.5rem 0 0.5rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: $darkTextColor;

    &:focus {
        box-shadow: inset 0 0 0 0.1rem rgba(198, 198, 198, 0.1);
        border: 1px solid #E9E9E9;
        background-color: #F8F8F8;
    }
}

.bootstrap-datetimepicker-widget {
    width: 270px !important;
    padding: 10px !important;
    border: 1px solid #ececec !important;
    border-radius: 2px !important;
    box-shadow: 5px 12px 30px rgba(0, 0, 0, 0.35) !important;

    td {
        padding: 8px !important;
        border-radius: 2px !important;

        .active,
        .active:hover {
            background-color: #c8c8c8 !important;
            border-radius: 2px !important;
            text-shadow: none !important;
        }
    }

    th {
        height: 34px !important;
        line-height: 34px !important;
        border-radius: 2px !important;

        .next,
        .prev {
            font-size: 18px !important;
        }
    }

    &.bottom:before {
        border-bottom: 7px solid #000000 !important;
        border-bottom-color: rgba(138, 138, 138, 0.14) !important;
    }

    &.top {
        position: absolute !important;
    }
}
