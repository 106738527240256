.sc-contact-us {

    .form-check-label a {
        color: $linksColor;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &-item {
        &-top {
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            @include media-breakpoint-between(xl, xxl) {
                flex-direction: column;
            }
        }

        &-info {
            @include media-breakpoint-up(md) {
                padding-right: 1rem;
            }

            @include media-breakpoint-up(lg) {
                padding-right: 1.5rem;
            }

            &-bottom {
                @media (max-width: $break-xxs) {
                    flex-direction: column;
                }
            }
        }

        &-subtitle {
            white-space: nowrap;
        }

        &-shedule {
            @media (max-width: $break-xxs) {
                width: 100%;
            }

            ul {
                line-height: 26px;
            }
        }

        &-phones {
            @media (max-width: $break-xxs) {
                width: 100%;
            }

            ul {
                line-height: 30px;
            }

            a {
                white-space: nowrap;
            }
        }

        &-img {
            @include media-breakpoint-down(md) {
                padding-top: 1rem;
            }

            @include media-breakpoint-between(xl, xxl) {
                padding-top: 1rem;
            }

            img {
                block-size: auto;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                @include media-breakpoint-up(md) {
                    max-width: 240px;
                }
            }
        }

        iframe {
            width: 100%;
        }
    }
}