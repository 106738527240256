.sc-last-reviews {
    &-item {
        @include media-breakpoint-up(md) {
            width: 50%;
        }

        @include media-breakpoint-up(xl) {
            width: 33.33333333%;
        }

        .sc-module-rating {
            align-self: flex-start;
        }

        &:hover a {
            text-decoration: underline;
        }

        a:hover {
            color: $linksColor;
        }
    }
}