.sc-advantages {
    @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
        overflow-x: auto;
        max-width: 100vh;

        .content-block:hover {
            box-shadow: none;
        }
    }

    &-item {
        flex: 0 0 70%;
        width: 70%;

        @include media-breakpoint-up(sm) {
            flex: 0 0 50%;
            width: 50%;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 33.3333%;
            width: 33.3333%;
        }

        @include media-breakpoint-up(xxl) {
            flex: 0 0 16.6666%;
            width: 16.6666%;
        }

        &:hover {
            .sc-advantages-item-title:not(span) {
                text-decoration: underline;

                &:hover {
                    color: $linksColor;
                    text-decoration: none;
                }
            }
        }

        &-text {
            @include media-breakpoint-up(xl) {
                max-width: 85%;
            }
        }
    }
}