@include media-breakpoint-down(md) {
    #search {
        padding: 1rem;
        position: relative;
        height: 100%;

        .form-control {
            height: auto !important;
        }
    }

    #sc-search-button {
        position: absolute;
        z-index: 2;
        top: 1.0875rem;
        right: 1rem;
        background: transparent;
        border: 0;

        &:hover {
            background: transparent;

            .sc-btn-icon {
                background: $primaryColor;
            }
        }
    }
}

.sc-livesearch {
    background: #f2f4f8;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease;
    z-index: 1040;
    position: absolute;

    @include media-breakpoint-down(md) {
        inset: 70px 0 0 0;
    }

    @include media-breakpoint-up(md) {
        width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    &.expanded {
        max-height: 100%;

        @media (min-width: $break-md) {
            max-height: 480px;
        }
    }

    &-close {
        position: absolute;
        top: 38px;
        right: 0;
        width: 40px;
        height: 40px !important;
        background: $primaryColor;
        color: #FFFFFF;
        opacity: 0;
        padding: 0 !important;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        z-index: 1041;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        @include media-breakpoint-down(md) {
            display: none !important;
        }

        @media (min-width: $break-md) {
            right: -40px;
        }

        span {
            mask-size: cover;
            mask-repeat: no-repeat;
            width: 25px;
            height: 25px;
            background: #FFFFFF;
            display: block;
            pointer-events: none;
            transition: transform 0.4s ease;
            mask-image: url("../img/sprite.svg#include--close-icon");
        }

        &.visible {
            opacity: 1;
        }

        &:hover span {
            transform: rotate(180deg);
        }
    }

    &-list {
        padding: 1rem 1rem 0 1rem;
        max-height: 100%;
        overflow-y: auto;
        min-height: 100px;

        @media (min-width: $break-md) {
            padding: 1rem 0.5rem 0 1rem;
            max-height: 456px;
        }

        .content-block {
            flex-direction: row !important;
            flex-wrap: wrap;
            height: auto !important;
            position: relative;
        }

        .sc-module-img {
            flex: 0 0 100px;
            max-width: 100px;
            align-self: center;
        }

        .sc-module-caption {
            flex: 0 0 calc(100% - 100px);
            max-width: calc(100% - 100px);
            padding-left: 1rem;
        }

        .sc-module-price-box {
            padding-bottom: 0 !important;
            padding-right: 3rem;
            display: flex;
            flex-direction: column;

            .price-tax {
                margin-bottom: 0.25rem;
            }
        }

        .sc-module-cart {
            .sc-btn-primary {
                position: absolute;
                right: 1rem;
                bottom: 1rem;
            }

            .sc-btn-text {
                display: none;
            }
        }
    }

    &-btn {
        border-top: $mainBorder;
    }
}

// End of Search