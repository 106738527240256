.sc-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 500;
    color: $darkTextColor;
    padding: 0.985rem 2rem;
    white-space: nowrap;
    border: 1px solid transparent;
    filter: drop-shadow(0px 0px 1px rgba(40, 41, 61, 0.08)) drop-shadow(0px 0.5px 2px rgba(96, 97, 112, 0.16));


    &:not([disabled]) {
        @include btn-anim;
    }

    &[disabled] {
        box-shadow: none;
    }

    &-icon {
        mask-size: cover;
        mask-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background: $darkTextColor;
        display: block;
        transition: background $mainTransition;
        pointer-events: none;

        // icons
        &-account {
            mask-image: url("../img/sprite.svg#include--top-account-icon");
        }

        &-cart {
            mask-image: url("../img/sprite.svg#include--btn-cart-icon");

            &-img {
                background: transparent !important;
            }
        }

        &-catalog {
            mask-image: url("../img/sprite.svg#include--btn-catalog-icon");
        }

        &-chevron {
            mask-image: url("../img/sprite.svg#include--location-chevron");
            width: 10px;
            height: 10px;
        }

        &-compare {
            mask-image: url("../img/sprite.svg#include--header-btns-compare");
        }

        &-close {
            mask-image: url("../img/sprite.svg#include--close-icon");
            width: 38px;
            height: 38px;
        }

        &-del {
            mask-image: url("../img/sprite.svg#include--btn-del-icon");
        }

        &-filter {
            mask-image: url("../img/sprite.svg#include--filter-icon");
        }

        &-list {
            mask-image: url("../img/sprite.svg#include--list-view-icon");
        }

        &-location {
            mask-image: url("../img/sprite.svg#include--top-location-icon");
        }

        &-menu {
            mask-image: url("../img/sprite.svg#include--top-menu-icon");
        }

        &-phone {
            mask-image: url("../img/sprite.svg#include--phone-icon");
        }

        &-price {
            mask-image: url("../img/sprite.svg#include--price-view-icon");
        }

        &-search {
            mask-image: url("../img/sprite.svg#include--header-seacrh-icon");
        }

        &-showmore {
            mask-image: url("../img/sprite.svg#include--show-more-icon");
        }

        &-viewed {
            mask-image: url("../img/sprite.svg#include--header-btns-viewed");
        }

        &-wishlist {
            mask-image: url("../img/sprite.svg#include--header-btns-wishlist");
        }

        & + .sc-btn-text {
            padding-left: 0.5rem;
        }
    }

    @keyframes btnsIconAnimation {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(1.5);
        }

        50% {
            transform: scale(1.25);
        }

        25% {
            transform: scale(1.5);
        }

        100% {
            transform: scale(1);
        }
    }

    &:active .sc-btn-icon {
        animation: btnsIconAnimation 2s infinite;
    }

    &-text {
        pointer-events: none;
    }

    &.small {
        font-size: 0.625rem;
        padding: 0.5rem 1.5rem;
    }

    &.no-btn {
        @include no-btn;
        filter: none;
    }

    &-with-icon {
        padding: 0.5rem;
    }

    &:not(.sc-btn-with-icon) {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &-danger,
    &-primary,
    &-secondary {
        border: 0;
    }

    &-primary:not([disabled]) {
        background: $primaryColor;
        color: #FFFFFF;

        &:hover {
            opacity: 0.9;
        }

        .sc-btn-icon {
            background: #FFFFFF;
        }
    }

    &-secondary:not([disabled]) {
        background: $primaryLightColor;

        &:hover,
        &.clicked {
            background: $primaryColor;
            color: #FFFFFF !important;

            .sc-btn-icon {
                background: #FFFFFF;
            }
        }
    }

    &-danger:not([disabled]) {
        background: $redColor;
        color: #FFFFFF;

        &:hover {
            background: rgba($redColor, 0.8);
        }

        .sc-btn-icon {
            background: #FFFFFF;
        }
    }

    &-outline {
        background: transparent;
        box-shadow: none;
        border-color: $primaryLightColor;

        &-primary:not([disabled]) {
            border-color: $primaryColor;
            color: $primaryColor;

            .sc-btn-icon {
                background: $primaryColor;
            }

            &:hover {
                background: $primaryColor;
                color: #FFFFFF;

                .sc-btn-icon {
                    background: #FFFFFF;
                }
            }
        }

        &-secondary:not([disabled]) {
            background: #FFFFFF;
            &:hover {
                background: $primaryColor;

                .sc-btn-icon {
                    background: #FFFFFF;
                }
            }
        }
    }
}

.sc-switcher {
    background: $primaryColor;

    button {
        @include no-btn(flex);
        color: #fff;
        line-height: 1rem;
        padding: 0 0.5rem;
        transition: background $mainTransition;

        &.active {
            background: #FAFAFC;
            color: $darkTextColor;
            border: 1px solid #F2F2F5;
        }
    }
}

.sc-filter-btn {
    top: 60px;
    z-index: 10;
    
    .sc-btn {
        padding: 0.75rem 2rem;
    }
}