.sc-shop-reviews {
    &-top {
        border-bottom: $mainBorder;

        > *:first-child {
            width: 100%;

            @media (min-width: $break-md) {
                width: auto;
            }
        }
    }

    &-rating {
        width: 100%;

        @media (min-width: $break-md) {
            width: auto;
        }

        &-subject {
            margin-right: 1rem;
            font-size: 0.875rem;

            @media (min-width: $break-md) {
                font-size: 1rem;
            }

            @media (min-width: $break-lg) {
                margin-right: 110px;
            }
        }
    }

    .sc-product-content-reviews-rating-value {
        width: 36px;
        max-width: 36px;
        color: $darkTextColor;
    }

    &-item {
        &-details {
            min-width: 200px;
            opacity: 0;
            visibility: hidden;
            transition: opacity $mainTransition;
            top: 2rem;
            box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);

            &-link:hover+.sc-shop-reviews-item-details {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

