@mixin bg ($size:"contain", $position: "center") {
	background-size: #{$size};
	background-position: #{$position};
	background-repeat: no-repeat;
}

@mixin content-block($padding:"1rem") {
	border: 1px solid #F2F4F8;
	background-color: #FFFFFF;
	color: $lightTextColor;
	padding: #{$padding};
	transition: box-shadow $mainTransition;
	border-radius: 0.25rem;

	&:hover {
		box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
	}
}

@mixin btn_anim {
	transition: background $mainTransition, color $mainTransition, filter $mainTransition;
	backface-visibility: hidden;
	-webkit-font-smoothing: subpixel-antialiased;

	&:focus {
		outline: transparent;
	}
}

@mixin no-btn ($display:"inline-block") {
	padding: 0;
	margin: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	appearance: none;
	display: #{$display};
}
