.sc-blog {
    &-search {
        .sc-btn-with-icon {
            @include no-btn;
            position: absolute;
            z-index: 2;
            top: calc(50% - 7px);
            right: 1.75rem;

            .sc-btn-icon {
                width: 14px;
                height: 14px;
            }

            &:hover {
                background-color: transparent;

                .sc-btn-icon {
                    background: $darkTextColor;
                }
            }
        }

        input[type="text"] {
            padding: 0.5rem 0.75rem;
        }
    }

    &-category {
        @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-right: 0;
        }

        .sc-last-news-item {
            flex: 0 0 auto;

            @include media-breakpoint-up(md) {
                &:first-child,
                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0;
                }
            }

            @include media-breakpoint-up(xl) {
                width: 33.33333333%;

                &:first-child,
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    margin-top: 0;
                }
            }
            
        }
    }

    &-post {
        &-photo {
            border: $mainBorder;
        }

        p + img {
            margin-top: 1rem;
        }

        &-gallery {

            > .row {
                @include media-breakpoint-down(lg) {
                    flex-wrap: nowrap;
                    overflow: hidden;
                    overflow-x: auto;

                    > * {
                        width: 30%;
                    }
                }
            }

            &-item {
                position: relative;
                z-index: 1;
                transition: box-shadow $mainTransition;
                overflow: hidden;
                display: block;
                margin-bottom: 1.5rem;

                span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: transparent;
                    transition: background 0.46s ease 0.04s, width 0.5s ease, height 0.5s ease;

                    &:before {
                        content: '';
                        background: url(../img/sprite.svg#include--zoom-icon) no-repeat;
                        width: 54px;
                        height: 54px;
                        opacity: 0;
                        transition: opacity 0.3s ease 0.1s;
                        visibility: hidden;
                    }
                }

                &:hover {
                    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.03);

                    span {
                        background: rgba(33, 33, 33, 0.73);

                        &:before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }

        &-info {
            border-top: $mainBorder;
            border-bottom: $mainBorder;

            &-item {
                &:not(:last-child) {
                    margin-bottom: 1.5rem;
                    
                    @include media-breakpoint-up(md) {
                        margin-bottom: 0;
                        margin-right: 1.5rem;
                    }
                }

                &:before {
                    content: '';
                    margin-right: 0.5rem;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }

                &-date:before {
                    background: url(../img/sprite.svg#include--blog-post-date) no-repeat;
                }

                &-comments:before {
                    background: url(../img/sprite.svg#include--blog-post-comments) no-repeat;
                }

                &-views:before {
                    background: url(../img/sprite.svg#include--blog-post-views) no-repeat;
                }
            }
        }
    }
}