// slider
.slick {
    &-slider {

        &-btn {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            border: 0;
            display: none;
            box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
                0px 4px 8px rgba(96, 97, 112, 0.16);
            padding: 0.5rem;

            &[aria-disabled="true"] {
                opacity: 0;
            }

            .sc-btn-icon {
                mask: url("../img/sprite.svg#include--prev-slide-icon");
            }


            &-prev {
                left: 0.25rem;

                @include media-breakpoint-up(md) {
                    left: 0.75rem;
                }
            }

            &-next {
                right: 0.25rem;

                @include media-breakpoint-up(md) {
                    right: 0.75rem;
                }

                .sc-btn-icon {
                    transform: rotate(180deg);
                }

                &:active {
                    .sc-btn-icon {
                        animation: none;
                    }
                }
            }
        }
    }

    &-track {
        display: flex !important;
        align-items: stretch
    }

    &-dots {
        -webkit-tap-highlight-color: transparent;
        margin-top: auto;
        display: inline-flex;
        align-items: center;
        padding: 0 1rem 1rem;

        @include media-breakpoint-up(lg) {
            padding: 0 2rem 2rem;
        }

        li {
            display: inline-block;
            zoom: 1;

            button {
                width: 20px;
                height: 6px;
                padding: 0;
                margin-right: 0.5rem;
                background: #C7C9D9;
                display: block;
                backface-visibility: visible;
                transition: background $mainTransition, width $mainTransition, height $mainTransition;
                border-radius: 6px;
                text-indent: -9999px;
                overflow: hidden;
                cursor: pointer;
            }

            &.slick-active button {
                width: 24px;
                height: 8px;
                background: $primaryColor;
            }
        }
    }
}