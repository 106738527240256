.sc-table-compare {
    tbody {
        border: 0;
    }

    td {
        padding: 1.5rem;
        min-width: 180px;
        border-color: #F2F2F5;

        &:not(:first-child) {
            color: $lightTextColor;
        }
    }

    img:not(.sc-btn-icon) {
        border: $mainBorder;
    }

    .sc-module-price-actual,
    .sc-table-compare-title {
        color: $darkTextColor;
    }

    &-product {
        background: #FAFAFC;

        .sc-table-product-name {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-btn {
        .sc-btn {
            flex: 1 1 0;
            width: 180px;

            @media (max-width: $break-sm-max) {
                width: 100%;
                text-align: center;
            }
        }
    }
}
