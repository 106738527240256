.sc-subcategories {
    @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
        overflow-x: auto;

        > * {
            flex: 0 0 45%;
            max-width: 45%;
        }
    }

    &-item {
        padding: 1rem 0.5rem 1.5rem;
        color: #555770;

        &:hover {
            span {
                text-decoration: underline;

                &:hover {
                    color: $linksColor;
                    text-decoration: none;
                }
            }
        }
    }
}

.sc-category {
    &-products {
        @include media-breakpoint-down(sm) {
            padding-bottom: 1rem;

            &[data-type="width50"] {
                padding-bottom: 0.5rem;
            }
        }

        &.sc-module {

            @include media-breakpoint-up(sm) {
                background: transparent;
            }
        }

        & + .pagination {
            padding-top: 1rem;

            @include media-breakpoint-up(md) {
                padding-top: 1.5rem;
            }
        }
    }

    &-sort {

        @include media-breakpoint-down(sm) {
            &-row {
                background: #FFFFFF;
            }

            border: 0;
            padding: 1rem 0;

            &-limit {
                width: 100%;
            }

            #input-sort {
                max-width: calc(100% - 60px - 0.5rem);
            }

            #input-limit {
                flex: 0 0 60px;
                max-width: 60px;
            }

            &:hover {
                filter: none;
                box-shadow: none;
            }
        }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            background-color: #F4F7FA;
            background-image: url("../img/sprite.svg#include--input-sort");
            background-repeat: no-repeat;
            background-position: right 0.5rem center;
            background-size: 10px;
            cursor: pointer;
            outline: none;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0.875rem 1.25rem 0.875rem 0.875rem;
            border: 0;
            @include media-breakpoint-down(sm) {
                flex-grow: 1;
            }
            @include media-breakpoint-up(md) {
                max-width: 260px;
            }

            + select {
                margin-left: 0.5rem;
            }
        }

        button {
            width: 42px;
            filter: none;

            + button {
                margin-left: 0.5rem;
            }

            .sc-btn-icon {
                width: 1.5rem;
                height: 1.5rem;
                background: #C7C9D9;

                &-catalog {
                    width: 1.125rem;
                    height: 1.125rem;
                }
            }

            &.active {
                background: $primaryColor;

                .sc-btn-icon{
                    background: #FFFFFF;
                }
            }
        }
    }

    &-search-title {
        font-size: 0.875rem;

        @include media-breakpoint-up(md) {
            font-size: 1.25rem;
        }
    }

    &-description {
        &-img {
            @include media-breakpoint-down(md) {
                margin: 0 auto;
                display: block;
            }
        }

        &-text p {
            color: $secondaryTextColor;
        }

        @include media-breakpoint-down(sm) {
            &-row {
                background: #ffffff;

                .content-block {
                    border: 0;
                    padding-left: 0;
                    padding-right: 0;

                    &:hover {
                        filter: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .product-layout {
        @include media-breakpoint-up(lg) {
            &:hover {
                z-index: 3;
                
                .sc-module-btns {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .product-grid {
        .content-block {
            min-height: 100%;
            position: relative;
            z-index: 1;
        }

        .product-layout-caption {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        .sc-module-caption {
            flex-grow: 1;
        }

        .sc-category-attr {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -100%;
            transform: translateY(100%);
            background: #F2F4F8;
            border: 1px solid #F2F2F5;
            border-radius: 0px 0px 4px 4px;
            opacity: 0;
            visibility: hidden;
            transition: opacity $mainTransition;

            &-item:not(:first-child) {
                margin-top: 0.25rem;
            }
        }

        &:hover {
            z-index: 3;

            .sc-category-attr {
                opacity: 1;
                bottom: 0;
                visibility: visible;
                box-shadow: 0px 2px 0 rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
            }
        }
    }

    .product-list {
        .content-block {
            flex-direction: row !important;
            align-items: center;
            padding: 0;
            position: relative;
        }

        .sc-module-img {
            padding: 1rem;
            max-width: 192px;
        }

        .sc-module-img img {
            max-width: 160px;
            block-size: auto;
        }

        .sc-module-btns {
            top: 1rem;
            right: 1rem;
        }

        .sc-module-caption {
            justify-content: flex-start !important;
            height: auto !important;
            min-height: 100%;
            border-left: $mainBorder;
            border-right: $mainBorder;
            flex-grow: 1;
            padding: 1rem 1rem 66px !important;
        }

        .sc-module-price-box {
            margin-top: 1rem !important;
        }

        .sc-module-cart {
            justify-content: flex-start !important;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            min-width: 188px;
            position: absolute;
            left: 185px;
            bottom: 1rem;
        }

        .sc-module-quantity {
            margin: 0 1.5rem 0 0;
        }

        .sc-category-attr {
            margin: 0 1.5rem;
            border: 1px solid #EBEBF0;
            border-radius: 0.25rem;
            flex: 0 0 260px;
            max-width: 260px;

            @include media-breakpoint-between(md, lg) {
                flex: 0 0 200px;
                max-width: 200px;
            }

            &-item:not(:first-child) {
                margin-top: 0.25rem;
            }
        }
    }

    .product-price {
        .content-block {
            flex-direction: row !important;
            align-items: center;
            padding: 0;
        }

        .sc-module-img {
            padding: 1rem;
            flex: 0 0 112px;
            max-width: 112px;

            @media (min-width: $break-xxl) {
                flex: 0 0 128px;
                max-width: 128px;
                padding: 1.5rem;
            }

            img {
                max-width: 80px;
                block-size: auto;
            }
        }

        .sc-module-stickers {
            display: none !important;
        }

        .sc-module-caption {
            flex-grow: 1;
            flex-direction: row !important;
            align-items: center;
            padding: 0 !important;
            justify-content: flex-start !important;
        }
        .sc-module-info {
            flex-grow: 1;

            @include media-breakpoint-down(xxl) {
                padding: 0 1rem;
                border-left: $mainBorder;
                border-right: $mainBorder;
            }
            @include media-breakpoint-up(xxl) {
                display: flex;
            }
        }

        .sc-module-product-info {
            @include media-breakpoint-up(xxl) {
                padding: 0 1rem;
                border-right: $mainBorder;
                width: 155px;
                text-align: center;
                min-height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @media (min-width: $break-xxl) {
                padding: 0 1.5rem;
                width: 168px;
            }

            @include media-breakpoint-down(xxl) {
                margin-bottom: 1rem;
            }
        }

        .sc-module-model {
            margin-top: 0 !important;
            white-space: nowrap;
        }

        .sc-module-price-box {
            margin-top: 0 !important;
            padding: 0 1rem !important;
            border-right: $mainBorder;
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            min-height: 100%;
            justify-content: center;
            align-items: end;

            @media (min-width: $break-xxl) {
                padding: 0 1.5rem !important;
                width: 168px;
            }

            @include media-breakpoint-down(xxl) {
                margin-left: auto;
            }
        }

        .price-tax {
            font-size: 10px !important;
        }

        .sc-module-cart {
            padding-right: 1.5rem;
            min-width: 234px;

            @include media-breakpoint-up(xxl) {
                margin-left: auto;
            }

            @media (min-width: $break-xxl) {
                min-width: 290px;
                justify-content: center !important;
            }
        }

        .sc-module-quantity {
            margin: 0 1.5rem;
            order: 0;
        }

        .sc-btn-primary {
            order: 1;
        }

        .sc-module-title {
            max-width: 212px;
            @include media-breakpoint-down(xxl) {
                display: block;
                margin-top: 1rem;
            }
            @include media-breakpoint-up(lg) {
                max-width: 360px;
            }
            @include media-breakpoint-up(xxl) {
                padding: 2rem 1rem;
                border-left: $mainBorder;
                border-right: $mainBorder;
                flex: 0 0 300px;
                max-width: 300px;

                @media (min-width: $break-xxl) {
                    padding: 2rem;
                    flex: 0 0 400px;
                    max-width: 400px;
                }
            }
        }

        .sc-category-attr,
        .sc-module-btns {
            display: none !important;
        }
    }
}

.sc-module-showmore.d-none + .pagination {
    margin-top: 1.5rem !important;
}

.sc-search-block {

    & + .sc-filter-btn {
        margin-top: 1rem;

        .sc-btn {
            margin-bottom: 0 !important;
        }
    }

    &-title {
        letter-spacing: 0.4px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    &-form {
        input,
        select {
            height: 40px;
        }

        input {
            width: 100%;
            margin-bottom: 1rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                margin-right: 1rem;
                max-width: 420px;
            }
        }

        select {
            color: $lightTextColor;
            font-weight: 500;
            background-color: #F4F7FA;
        }
    }
}