.sc-loader {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: rgb(127, 127, 127);
    box-shadow: 2rem 0 rgb(96, 96, 96), -2rem 0 rgb(85, 85, 85);
    position: relative;
    animation: flash 0.5s ease-out infinite alternate;

    &-overlay {
        position: fixed;
        z-index: 19999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

@keyframes flash {
    0% {
        background-color: #FFF2;
        box-shadow: 2rem 0 #FFF2, -2rem 0 rgb(41, 40, 40);
    }

    50% {
        background-color: rgb(41, 40, 40);
        box-shadow: 2rem 0 #FFF2, -2rem 0 #FFF2;
    }

    100% {
        background-color: #FFF2;
        box-shadow: 2rem 0 rgb(41, 40, 40), -2rem 0 #FFF2;
    }
}